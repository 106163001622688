import SockJS from 'sockjs-client'
import Stomp from 'stompjs'
import { getjwt } from '~/api/common'
const wsConnect = {
  javaWebsocketToken: '',
  subscriptPath: '',
  init: async function (subscriptPath, subscribeList = []) {
    if (!global) {
      global = window
    }

    if (!subscriptPath) {
      subscriptPath = `/exchange/steam-stock.broadcast/${useCookie('i18n_redirected').value ?? 'zh'}`
    }
    wsConnect.subscriptPath = subscriptPath
    wsConnect.javaWebsocketToken = window.localStorage.getItem('javaWebsocketToken') || null
    let jwtResult = {}
    if (wsConnect.javaWebsocketToken) {
      jwtResult = JSON.parse(
        decodeURIComponent(escape(window.atob(wsConnect.javaWebsocketToken.split('.')[1].replace(/-/g, '+').replace(/_/g, '/'))))
      )
    } else {
      jwtResult.exp = 0
    }
    if (!wsConnect.javaWebsocketToken || jwtResult.exp * 1000 < new Date().getTime()) {
      const data = await getjwt()
      if (data.data) {
        window.localStorage.setItem('javaWebsocketToken', data.data)
        wsConnect.javaWebsocketToken = data.data
      }
    }
    const runtimeConfig = useRuntimeConfig()
    const WS = new SockJS(runtimeConfig.public.wsBase)
    const stompClient = Stomp.over(WS)
    stompClient.debug = function (str) {
      // append the debug log to a #debug div somewhere in the page using JQuery:
      // console.log('StompClient debug', str)
    }
    stompClient.connect({ Authorization: 'Bearer ' + wsConnect.javaWebsocketToken }, frame => {
      wsConnect.sub = stompClient.subscribe(wsConnect.subscriptPath, res => {
        const type = JSON.parse(res.body).type
        for (let subscribe of subscribeList) {
          if (type == subscribe.key) {
            subscribe.callBack(JSON.parse(res.body))
          }
        }
      })
    })
    wsConnect.stompClient = stompClient
  },
  subscript: function (key, callBack) { },
  closeConnect: function() {
    if (wsConnect.stompClient !== null && wsConnect.stompClient.connected) {
      wsConnect.sub.unsubscribe()
      wsConnect.stompClient.disconnect(function() {
        console.log("Disconnected")
      })
    }
  }
}

async function getJWT() {
  console.log('getjwt', data.data)
  return data.data
}

export default wsConnect
